<template>
  <div>
    <v-progress-linear v-if="loading" :indeterminate="true"/>
    <v-container class="users" fluid v-if="!loading">
      <v-toolbar flat class="d-print-none pb-10">
        <v-text-field :value="datesFormatted"
                      label="Select dates"
                      single-line
                      hide-details
                      readonly
                      @click="showDatePicker=true"
                      append-icon="mdi-calendar"
        />
        <v-dialog
            v-model="showDatePicker"
            ref="dialog"
            width="290px"
            persistent
        >
          <v-date-picker
              v-model="dates"
              range
              no-title
              @close="closeDatePicker"
          ></v-date-picker>
          <v-btn @click="closeDatePicker">Close</v-btn>
        </v-dialog>
        <v-spacer/>
        <!--        <v-select-->
        <!--            label="Filter by stream"-->
        <!--            v-model="streamsSelected"-->
        <!--            :items="streams"-->
        <!--            multiple-->
        <!--            single-line-->
        <!--            hide-details-->
        <!--        />-->
        <!--        <v-spacer/>-->
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search..."
            single-line
            hide-details
        />
        <v-spacer/>
        <v-select
            style="max-width: 80px"
            single-line
            label="Columns"
            v-model="selectedHeaders"
            :items="headers"
            multiple
            hide-details
            return-object
            @change="saveSettings(selectedHeaders)"
            append-icon="mdi-dots-vertical"
        >
          <template v-slot:selection="{ index }">
            <span v-if="index===0" class="caption">
              Columns
            </span>
          </template>
        </v-select>
      </v-toolbar>
      <div>
        <v-data-table
            :headers="sortedHeaders"
            :items="feedback"
            item-key="id"
            :search="search"
            :loading="loading"
            sort-by="date"
            sort-desc
            dense
            calculate-widths
            show-group-by
        >
          <template v-slot:item.date="{ item }">
            {{ dateFormatted(item.date, {append: ' YYYY'}) }}
          </template>

          <template v-slot:item.title="{ item }">
            {{ item.title }}
            <br/>
            <MealInfo :meal-id="`${item.meal_id}`"/>
          </template>

          <template v-slot:group.header="{ items, group, headers, groupBy,remove }">
            <template v-for="({text,value},index) of headers">
              <td v-bind:key="value" v-if="index===0" colspan="2">
                by {{ groupBy.join(',') }}
                <v-icon @click="remove">mdi-close</v-icon>
              </td>
              <td v-bind:key="value" v-if="index>1">
                <span v-if="['overall','portion','preparation','presentation','taste'].includes(value)">
                  {{ avg(items, value) }}
                </span>
              </td>
            </template>
          </template>

          <template v-slot:item.email="{ item }">
            <span class="d-inline-block text-truncate caption" style="max-width: 150px">
              <a v-bind:href="`mailto:${item.email}`">{{ item.email }}</a>
            </span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn v-if="item.email"
                   icon
                   :to="{ name: 'Customers', query : { search : `${ item.email }` }}">
              <v-icon>mdi-account</v-icon>
            </v-btn>
            <v-btn v-if="item.email" icon
                   :to="{ name: 'Orders', query : { search : `${ item.email }`, dates: getDateString(item.date) }}">
              <v-icon> mdi-receipt</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </v-container>
  </div>
</template>


<script>
import {mapActions, mapGetters} from 'vuex'
import {dateFormatted, getDateString} from '@/store/utils';
import MealInfo from '@/components/MealInfo';
import moment from 'moment';
import api from '@/api';


function getSettingsKey() {
  return 'feedback-columns';
}

export default {
  components: {MealInfo},
  mounted() {
    try {
      this.selectedHeaders = JSON.parse(localStorage.getItem(getSettingsKey()));
    } catch (e) {
      console.warn('failed to restore customer-columns');
    }
    if (!this.selectedHeaders) {
      this.selectedHeaders = this.headers;
    }
  },
  data() {
    return {
      feedbackRecords: [],
      selectedHeaders: [],
      respondToRouteChanges: true,
      streamsSelected: [],
      selected: [],
      missing_meals: new Set(),
      missing_count: {},
      showDatePicker: false,
      dates: [],
      search: '',
      loading: false,
      post: null,
      error: null,
      headers: [
        "meal_id", "title", "stream", "tod", "overall", 'portion', "preparation",
        "presentation", "taste", "comments", "date", "email", "stream"
      ]
          .map(field => ({text: field, value: field}))
          .concat([{text: 'actions', value: 'actions', groupable: false, sortable: false, width: 120}]),
      showMissing: false,
      setRouteTimeout: null,
      filteredIngredients: [],
    }
  },
  created() {
    this.setSearch();
    this.setStreamsSelected();
    this.setDates();
    this.fetchData();
  },
  watch: {
// call again the method if the route changes
    'querySearch': 'setSearch',
    'queryStreams': 'setStreamsSelected',
    'queryDates': 'setDates',
    'dates': 'setRouteParams',
    'search': 'setRouteParams',
    'streamsSelected': 'setRouteParams'
  },
  computed: {
    ...mapGetters([
      'diets',
      'getDietName',
      'getDietStream',
      'getCustomer',
      'getMealNameAndDiet'
    ]),
    feedbackUnfiltered() {
      // const {dateFrom, dateTo} = this;
      this.feedbackRecords.forEach(f => f.customer = f.uid && this.getCustomer(f.uid));
      this.feedbackRecords.forEach(f => f.email = f.customer && f.customer.email);
      this.feedbackRecords.forEach(f => {
        const mealNameAndDiet = this.getMealNameAndDiet(f.meal_id);
        if (mealNameAndDiet && mealNameAndDiet.diets) {
          f.stream = mealNameAndDiet.diets.stream;
        }
      });
      return this.feedbackRecords;
    },
    feedback() {
      const feedback = this.feedbackUnfiltered;
      console.log('feedback', feedback);
      const streamsSelected = this.streamsSelected;
      if (streamsSelected.length > 0) {
        return feedback.filter(o => streamsSelected.includes(o.stream));
      } else
        return feedback;
    },
    sortedHeaders() {
      return this.headers.filter(h => this.selectedHeaders.find(i => i.value === h.value));
    },
    totalCount() {
      let count = 0;
      this.customers.forEach(o => count += o.quantity);
      return count;
    },
    streams() {
      const streams = new Set();
      this.customersUnfiltered.forEach(o => streams.add(o.plan));
      // console.log('streams', streams);
      return [...streams.values()].sort();
    },
    datesFormatted() {
      const format = 'ddd D MMM YYYY';
      if (!this.dateFrom) {
        return '';
      } else if (this.dateFrom === this.dateTo) {
        return `${moment(this.dateFrom).format(format)}`;
      } else {
        return `${moment(this.dateFrom).format(format)} - ${moment(this.dateTo).format(format)}`
      }
    },
    querySearch() {
      return this.$route.query.search || '';
    },
    queryDates() {
      // ensure this is always an array
      const queryDates = this.$route.query.dates;
      // console.log('queryDates',queryDates);
      if (queryDates) {
        return queryDates.filter ? queryDates : [queryDates];
      } else {
        return [];
      }
    },
    queryStreams() {
      // ensure this is always an array
      const query = this.$route.query.streams;
      if (query) {
        return (query.filter ? query : [query]);
      } else {
        return [];
      }
    },
    dateFrom() {
      const dates = [...this.dates].sort();
      return dates[0] || getDateString(moment().startOf('week'));
    },
    dateTo() {
      const dates = [...this.dates].sort().reverse();
      if (dates.length < 2) {
        return getDateString(moment(this.dateFrom).add(1, 'week'))
      } else {
        return dates[0];
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchFeedback',
      'fetchCustomers',
      'fetchMealNameAndDiet'
    ]),
    setSearch() {
      if (!this.respondToRouteChanges) {
        // console.log('ignoring since route changes ignored')
        return;
      }
      if (this.search !== this.querySearch)
        this.search = this.querySearch;
    },
    setStreamsSelected() {
      if (!this.respondToRouteChanges) {
        // console.log('ignoring since route changes ignored')
        return;
      }
      if (this.streamsSelected != this.queryStreams)
        this.streamsSelected = this.queryStreams;
    },
    setDates() {
      if (!this.respondToRouteChanges) {
        // console.log('ignoring since route changes ignored')
        return;
      }
      this.dates = this.queryDates
    },
    setRouteParams() {
      if (this.setRouteTimeout) {
        // console.log('setRouteParams reque')
        clearTimeout(this.setRouteTimeout);
      } else {
        // console.log('setRouteParams que')
      }
      this.setRouteTimeout = setTimeout(() => {
        // console.log('setRouteParams exec')
        this.setRouteTimeout = false;
        const queryChanges = {};
        if (JSON.stringify(this.dates) !== JSON.stringify(this.queryDates)) {
          queryChanges.dates = this.dates;
        }
        if (JSON.stringify(this.streamsSelected) !== JSON.stringify(this.queryStreams)) {
          queryChanges.streams = this.streamsSelected;
        }
        if (this.search !== this.querySearch) {
          queryChanges.search = this.search;
        }
        //
        // this.$router.push({query: {streams: this.streamsSelected}});
        if (Object.keys(queryChanges).length > 0) {
          // console.log('updating route', queryChanges);
          const newQuery = {...this.$route.query, ...queryChanges};
          Object.keys(newQuery).forEach(key => {
            if (!newQuery[key]) {
              delete newQuery[key];
            }
          })
          this.respondToRouteChanges = false;
          this.$router.replace({query: newQuery})
              .finally(() => {
                this.respondToRouteChanges = true;
              });
        } else {
          // console.log('route query does not need to be updated');
        }
      }, 500);
    },
    fetchData() {
      this.error = this.post = null
      this.loading = true;
      let {dateFrom, dateTo} = this;
      if (!dateFrom) {
        this.feedbackRecords = [];
        this.loading = false;
        return;
      }

      //dateTo = getDateString(moment(dateTo).endOf('week'));
      return Promise
          .all([
            api.get('feedback', {params: {dateFrom, dateTo}})
                .then(response => {
                  this.feedbackRecords = response.data;
                })
                .then(() => {
                  const mealIds = new Set();
                  this.feedbackRecords.map(f => f.meal_id).forEach(id => mealIds.add(id));
                  return Promise.all([...mealIds].map(id => this.fetchMealNameAndDiet(id)));
                })
                .catch((e) => {
                  console.error('something went wrong', e);
                })
                .finally(() => {
                  this.loading = false;
                }),
            this.fetchCustomers()
          ]);
    },
    meal_sizes() {
      return 'small,medium,large'.split(',');
    },
    saveSettings(selectedHeaders) {
      localStorage.setItem(getSettingsKey(), JSON.stringify(selectedHeaders));
    },
    async closeDatePicker() {
      this.showDatePicker = false;
      await this.fetchData();
    },
    dateFormatted,
    getDateString,
    avg(items, prop) {
      items = items.filter(i => !!(i[prop]));
      if (items.length > 0) {
        return (items.reduce((i, a) => a[prop] + i, 0) / items.length).toFixed(1);
      } else {
        return '';
      }
    }
  }
}
</script>
