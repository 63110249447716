import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":true}}):_vm._e(),(!_vm.loading)?_c(VContainer,{staticClass:"users",attrs:{"fluid":""}},[_c(VToolbar,{staticClass:"d-print-none pb-10",attrs:{"flat":""}},[_c(VTextField,{attrs:{"value":_vm.datesFormatted,"label":"Select dates","single-line":"","hide-details":"","readonly":"","append-icon":"mdi-calendar"},on:{"click":function($event){_vm.showDatePicker=true}}}),_c(VDialog,{ref:"dialog",attrs:{"width":"290px","persistent":""},model:{value:(_vm.showDatePicker),callback:function ($$v) {_vm.showDatePicker=$$v},expression:"showDatePicker"}},[_c(VDatePicker,{attrs:{"range":"","no-title":""},on:{"close":_vm.closeDatePicker},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}}),_c(VBtn,{on:{"click":_vm.closeDatePicker}},[_vm._v("Close")])],1),_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Search...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VSpacer),_c(VSelect,{staticStyle:{"max-width":"80px"},attrs:{"single-line":"","label":"Columns","items":_vm.headers,"multiple":"","hide-details":"","return-object":"","append-icon":"mdi-dots-vertical"},on:{"change":function($event){return _vm.saveSettings(_vm.selectedHeaders)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
return [(index===0)?_c('span',{staticClass:"caption"},[_vm._v(" Columns ")]):_vm._e()]}}],null,false,2533876083),model:{value:(_vm.selectedHeaders),callback:function ($$v) {_vm.selectedHeaders=$$v},expression:"selectedHeaders"}})],1),_c('div',[_c(VDataTable,{attrs:{"headers":_vm.sortedHeaders,"items":_vm.feedback,"item-key":"id","search":_vm.search,"loading":_vm.loading,"sort-by":"date","sort-desc":"","dense":"","calculate-widths":"","show-group-by":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormatted(item.date, {append: ' YYYY'}))+" ")]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" "),_c('br'),_c('MealInfo',{attrs:{"meal-id":("" + (item.meal_id))}})]}},{key:"group.header",fn:function(ref){
var items = ref.items;
var group = ref.group;
var headers = ref.headers;
var groupBy = ref.groupBy;
var remove = ref.remove;
return [_vm._l((headers),function(ref,index){
var text = ref.text;
var value = ref.value;
return [(index===0)?_c('td',{key:value,attrs:{"colspan":"2"}},[_vm._v(" by "+_vm._s(groupBy.join(','))+" "),_c(VIcon,{on:{"click":remove}},[_vm._v("mdi-close")])],1):_vm._e(),(index>1)?_c('td',{key:value},[(['overall','portion','preparation','presentation','taste'].includes(value))?_c('span',[_vm._v(" "+_vm._s(_vm.avg(items, value))+" ")]):_vm._e()]):_vm._e()]})]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate caption",staticStyle:{"max-width":"150px"}},[_c('a',{attrs:{"href":("mailto:" + (item.email))}},[_vm._v(_vm._s(item.email))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.email)?_c(VBtn,{attrs:{"icon":"","to":{ name: 'Customers', query : { search : ("" + (item.email)) }}}},[_c(VIcon,[_vm._v("mdi-account")])],1):_vm._e(),(item.email)?_c(VBtn,{attrs:{"icon":"","to":{ name: 'Orders', query : { search : ("" + (item.email)), dates: _vm.getDateString(item.date) }}}},[_c(VIcon,[_vm._v(" mdi-receipt")])],1):_vm._e()]}}],null,false,3723862553)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }