<template>
  <i v-if="loaded">{{ dietName }}</i>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';

export default {
  name: "MealInfo",
  data() {
    return {
      mealInfo: {},
      loaded: false
    }
  },
  props: {
    mealId: String
  },
  mounted() {
    return Promise
        .all([
          this.fetchMeal(this.mealId)
              .then(i => {
                this.mealInfo = i;
              }),
          this.fetchDiets()
        ])
        .then(() => this.loaded = true);
  },
  computed: {
    ...mapGetters([
      'getDietName'
    ]),
    dietName() {
      return this.getDietName(this.mealInfo.diet);
    }
  },
  methods: {
    ...mapActions([
      'fetchMeal',
      'fetchDiets'
    ]),
  }
}
</script>

<style scoped>

</style>
